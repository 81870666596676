import React from "react";
import { Link } from "react-router-dom";
import { formatUrl } from "../utils";

// components
import LazyLoad from "react-lazyload";

// types
import { Item, CloudinaryUrlConfig } from "../types";

const imageConfig: CloudinaryUrlConfig = {
  h: 600,
  c: "fit",
};

const BlankImage = () => (
  <div className="works__image works__image--grid-size-1 works__image--blank" style={{ background: "#fff" }} />
);

const ItemImage = ({ item }: { item: Item }) => (
  <Link
    to={`/${item.path}`}
    className={`works__image works__image--grid-size-${item.gridSize}`}
    style={{
      backgroundImage: item.thumbnail ? `url(${formatUrl(item.thumbnail, imageConfig)}` : "",
    }}
  />
);

interface Props {
  order: string[];
  items: { [key: string]: Item };
}

const Items = ({ order, items }: Props) => {
  return (
    <div className="works">
      {order.map((id, i) => {
        if (id === "blank") {
          return (
            <LazyLoad height={400} offset={500} once key={`blank_${i}`}>
              <BlankImage />
            </LazyLoad>
          );
        }

        return (
          <LazyLoad height={400} offset={500} once key={id}>
            <ItemImage item={items[id]} />
          </LazyLoad>
        );
      })}
    </div>
  );
};

export default Items;
