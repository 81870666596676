import React, { useState, useEffect } from "react";
import { Link, NavLink, RouteComponentProps } from "react-router-dom";

interface Props {
  location: Location;
}

const Nav: React.SFC<Props & RouteComponentProps> = ({ history }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      const bodyEl = document.querySelector("body");
      bodyEl && bodyEl.classList.add("scroll-lock");
    } else {
      const bodyEl = document.querySelector("body");
      bodyEl && bodyEl.classList.remove("scroll-lock");
    }
  }, [menuOpen]);

  useEffect(() => {
    return history.listen(() => {
      setMenuOpen(false);
    });
  });

  // don't show nav if on admin route
  if (location.pathname.includes("/admin")) return null;

  return (
    <header className={`main-header ${menuOpen ? "menu-open" : ""}`}>
      <div className="header-top">
        <Link to="/" className="main-header__heading">
          Sandy Harris
        </Link>
        <i className="material-icons menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? "close" : "menu"}
        </i>
      </div>
      <nav className="main-nav">
        <div className={`main-nav__links`}>
          <NavLink to="/work" className="main-nav__link">
            Work
          </NavLink>
          <NavLink to="/other" className="main-nav__link">
            Exhibitions & Residencies
          </NavLink>
          <NavLink to="/about" className="main-nav__link">
            About
          </NavLink>
          <NavLink to="/cv" className="main-nav__link">
            CV
          </NavLink>
          <NavLink to="/contact" className="main-nav__link">
            Contact
          </NavLink>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
