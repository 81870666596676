import React from "react";
import marked from "marked";
import { formatUrl } from "../utils";

// components
import { Helmet } from "react-helmet";

// types
import { Item, CloudinaryUrlConfig } from "../types";

interface Props {
  item: Item | undefined;
}

const imageConfig: CloudinaryUrlConfig = { h: "ih", c: "fit" };

const ItemComponent = ({ item }: Props) => {
  if (!item) {
    return <span className="not-found">This page does not exist 😞</span>;
  }

  return (
    <>
      <Helmet>
        <title>{`Sandy Harris - ${item.title || ""}`}</title>
      </Helmet>
      <div className="work">
        {item.images.map(imageId => {
          const description: string | void = item.imageDescriptions[imageId];

          return (
            <>
              <img className="work__image" key={imageId} src={formatUrl(imageId, imageConfig)} />

              {!description ? null : (
                <div
                  className="work__image__description"
                  dangerouslySetInnerHTML={{
                    __html: marked(description),
                  }}
                />
              )}
            </>
          );
        })}
        <div className="work__info">
          <h2 className="work__info__heading">{item.title}</h2>
          <span className="work__info__description">{item.description}</span>
        </div>
      </div>
    </>
  );
};

export default ItemComponent;
