import React from "react";
import "./Loading.css";
import img from "./Loading.png";

interface Props {
  style?: React.CSSProperties;
}

const Loading: React.SFC<Props> = ({ style }) => {
  return <div className="Spinner" style={{ ...(style || {}), backgroundImage: `url(${img})` }} />;
};

export default Loading;
