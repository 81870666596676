import { Item, CloudinaryUrlConfig } from "./types";

export const formatUrl = (id: string, config: CloudinaryUrlConfig = { w: 500, h: 500, c: "fit" }): string => {
  const configString = Object.keys(config)
    // TODO: fix this
    // @ts-ignore
    .map(key => (config[key] ? `${key}_${config[key]}` : ""))
    .join(",");

  return `https://res.cloudinary.com/aeu/image/upload/${configString}/${id}.jpg`;
};

export const getItemByPath = (works: { [key: string]: Item }, path: string): Item | undefined => {
  for (let workId in works) {
    const work: Item = works[workId];
    if (work.path === path) {
      return work;
    }
  }
  return undefined;
};

export const b64EncodeUnicode = (str: string): string =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match: string, p1: string) =>
      String.fromCharCode(Number("0x" + p1))
    )
  );

export const b64DecodeUnicode = (str: string): string =>
  decodeURIComponent(
    atob(str)
      .split("")
      .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
