import React from "react";
import { BrowserRouter as Router, Route, Switch, RouteComponentProps, RouteProps } from "react-router-dom";
import marked from "marked";

import dataJson from "../data.json";

// css
import "./Site.css";

// Components
import ItemGrid from "./ItemGrid";
import ItemPage from "./ItemPage";
import Nav from "./Nav";
import Loading from "./Loading";

import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import { SiteData } from "../types";
import { getItemByPath } from "../utils";

// TODO: there is probably a better way to do this
const data = dataJson as unknown as SiteData;

const loadableOptions: Loadable.OptionsWithoutRender<any> = {
  loader: () => import("./Admin/Admin"),
  loading: () => <Loading style={{ gridRow: 2 }} />,
};

const Admin = Loadable(loadableOptions);

const Site = () => {
  return (
    <>
      <Helmet>
        <title>Sandy Harris</title>
      </Helmet>
      <Router>
        <div className="Site">
          <Route path="/" component={Nav} />
          <Switch>
            <Route
              exact
              path="/"
              render={props => <ItemGrid {...props} items={data.items} order={data.worksOrder} />}
            />
            <Route
              exact
              path="/work"
              render={props => <ItemGrid {...props} items={data.items} order={data.worksOrder} />}
            />
            <Route
              exact
              path="/other"
              render={props => <ItemGrid {...props} items={data.items} order={data.exhibitionsOrder} />}
            />
            <Route
              path="/about"
              render={() => <div className="bio" dangerouslySetInnerHTML={{ __html: marked(data.bio) }} />}
            />
            <Route
              path="/cv"
              component={() => <div className="cv" dangerouslySetInnerHTML={{ __html: marked(data.cv) }} />}
            />
            <Route path="/contact" render={() => <div className="contact">{data.contact}</div>} />
            <Route path="/admin" component={Admin} />
            <Route
              path="/:itemPath"
              render={({ match }) => (
                <ItemPage item={getItemByPath(data.items, match.params ? match.params.itemPath : undefined)} />
              )}
            />
            <Route
              render={() => (
                <div className="df">
                  <span className="not-found">This page does not exist 😞</span>
                </div>
              )}
            />
          </Switch>
        </div>
      </Router>
    </>
  );
};

export default Site;
